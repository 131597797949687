import * as React from "react";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkFloatingFullWidthButton, UitkTertiaryButton, UitkButtonSize } from "@egds/react-core/button";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { UitkRadioButton, UitkRadioButtonGroup } from "@egds/react-core/radio-button";
import { UitkLink } from "@egds/react-core/link";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { Room } from "./Room";
import { locKeys } from "../l10n";
import { TravelersPickerProps, AgeOption } from "../typings";
import { InfantSeating } from "../InfantSeating";
import { travelersMetadata } from "../utils";
import { useLocalization } from "@shared-ui/localization-context";
import { inject } from "mobx-react";
import { getTracker } from "utils/TrackingUtils";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkScrollable } from "@egds/react-core/scrollable";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";

export const TravelersPicker = inject("analytics")((props: TravelersPickerProps) => {
  const childAgeOptions: AgeOption[] = [];
  const infantAgeOptions: AgeOption[] = [];
  const { formatText } = useLocalization();

  const populateAgeOptions = (
    minAge: number,
    maxAge: number,
    youngestAgeTextToken: string,
    ageOptions: AgeOption[]
  ) => {
    for (let i = minAge; i <= maxAge; i++) {
      const name: string = i === minAge && !!youngestAgeTextToken ? formatText(youngestAgeTextToken) : i.toString();
      ageOptions.push({
        value: i.toString(),
        name,
      });
    }
  };

  populateAgeOptions(
    props.config.minChildAge,
    props.config.maxChildAge,
    props.config.youngestChildAgeTextToken,
    childAgeOptions
  );
  populateAgeOptions(
    props.config.minInfantAge,
    props.config.maxInfantAge,
    props.config.youngestInfantAgeTextToken,
    infantAgeOptions
  );

  const onAddRoom = () => {
    props.setTravelers({
      rooms: props.travelers.rooms.concat({ adults: 1, children: [], infants: [] }),
      infantSeating: props.travelers.infantSeating,
    });
    liveAnnounce(formatText(locKeys.roomAdded, props.travelers.rooms.length + 1), Level.POLITE);
    props.setTotalRoomRefs(props.totalRoomRefs + 1);

    tracker.trackEvent(`${_guests}add-room`);
  };

  const onInfantSeatingChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    props.setTravelers({
      rooms: props.travelers.rooms,
      infantSeating: event.currentTarget.value,
    });
  };

  const onSave = () => {
    props.onSave(props.travelers);
  };

  const tracker = getTracker(props.lobState.trackingEnabled, props.analytics, props.lobState.moduleName);
  const _guests = "guests.";

  const { numOfChildren, numOfInfants } = travelersMetadata(props.travelers);
  const showAddRoomButton: boolean = props.config.withRooms && props.travelers.rooms.length < props.config.maxRooms;
  const showInfantSeatingOptions: boolean = props.config.withInfants && props.config.withFlight && !!numOfInfants;
  const showAirlineAgeRules: boolean =
    props.config.withFlight &&
    props.config.withAges &&
    (!!numOfChildren || (props.config.withInfants && !!numOfInfants));
  const airlineChildSeatingMessage =
    !!numOfInfants && numOfInfants > 1 ? locKeys.airlineChildSeatingMessagePlural : locKeys.airlineChildSeatingMessage;

  return (
    <>
      <UitkSpacing padding={{ inline: "one", blockstart: "half", blockend: "twelve" }}>
        <UitkScrollable direction="vertical">
          <section>
            {props.travelers.rooms.map((room, index) => (
              <Room
                setTravelers={props.setTravelers}
                travelers={props.travelers}
                key={index}
                index={index}
                room={room}
                config={props.config}
                roomRefs={props.roomRefs}
                totalRoomRefs={props.totalRoomRefs}
                setTotalRoomRefs={props.setTotalRoomRefs}
                childAgeOptions={childAgeOptions}
                infantAgeOptions={infantAgeOptions}
                lobState={props.lobState}
              />
            ))}
            {showAddRoomButton && (
              <UitkLayoutFlex justifyContent="end">
                <UitkLayoutFlexItem className="addRoomButton">
                  <UitkTertiaryButton onClick={onAddRoom} data-testid="add-room-button">
                    {formatText(locKeys.addRoom)}
                  </UitkTertiaryButton>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            )}
            {showInfantSeatingOptions && (
              <UitkSpacing padding={{ blockstart: "six" }}>
                <div className="airlineChildSeating">
                  <UitkSpacing padding={{ blockend: "two" }}>
                    <UitkText size={300}>{formatText(airlineChildSeatingMessage)}</UitkText>
                  </UitkSpacing>
                  <UitkRadioButtonGroup
                    group="InfantSeating"
                    onChange={onInfantSeatingChange}
                    selected={props.travelers.infantSeating}
                  >
                    <UitkSpacing padding={{ inlineend: "six" }}>
                      <UitkRadioButton id="ChildOnLap" value={InfantSeating.ON_LAP}>
                        {formatText(locKeys.airlineSeatingOnLap)}
                      </UitkRadioButton>
                    </UitkSpacing>
                    <UitkSpacing padding={{ inlineend: "six" }}>
                      <UitkRadioButton id="ChildInSeat" value={InfantSeating.IN_SEAT}>
                        {formatText(locKeys.airlineSeatingInSeat)}
                      </UitkRadioButton>
                    </UitkSpacing>
                  </UitkRadioButtonGroup>
                </div>
              </UitkSpacing>
            )}
            {showAirlineAgeRules && (
              <div>
                <UitkLink size="small" className="airlineAgeRules">
                  <a
                    href="/p/info-main/h_fw_default?rhtx=HTX_FLTSRCH_RT#travs"
                    id="airline-age-rules"
                    target="_blank"
                    rel="noopener"
                  >
                    {formatText(locKeys.airlineAgeRules)}
                    <UitkIcon id="airline-age-rules-opens-in-new" name="open_in_new" title="Opens in a new window" />
                  </a>
                </UitkLink>
              </div>
            )}
            <UitkSpacing padding={{ blockstart: "two", blockend: "six" }}>
              <div />
            </UitkSpacing>
          </section>
        </UitkScrollable>
      </UitkSpacing>
      <UitkLayoutPosition type="sticky" position={{ bottom: "zero" }}>
        <UitkScrim className="guestsDoneBtn" type={UitkScrimType.BUTTON}>
          <UitkFloatingFullWidthButton
            onClick={onSave}
            size={UitkButtonSize.LARGE}
            isFullWidth
            data-testid="guests-done-button"
          >
            {formatText(locKeys.doneLabel)}
          </UitkFloatingFullWidthButton>
        </UitkScrim>
      </UitkLayoutPosition>
    </>
  );
});
