import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { observer, inject } from "mobx-react";
import { UitkFakeInput } from "@egds/react-core/input";
import { TravelersDialog } from "./TravelersDialog";
import { TravelersHiddenInputs } from "./TravelersHiddenInputs";
import { TravelersFieldProps, Travelers, TravelersTriggerTypes } from "../typings";
import { travelersSummary } from "../utils";
import { useLocalization } from "@shared-ui/localization-context";
import { FlightWizardState, HotelWizardState } from "src/stores/wizard/state";
import { InfantSeating } from "src/components/shared/TravelersField";
import { ACTION_CLOSE, ACTION_DONE, ACTION_OPEN, getTracker } from "utils/TrackingUtils";
import { UitkLink } from "@egds/react-core/link";
import { UitkIcon } from "@egds/react-core/icons";
import { PositionType } from "@egds/react-core/menu";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { locKeys } from "../l10n";
import { AdaptiveMenuDialog } from "../../AdaptiveMenuDialog/AdaptiveMenuDialog";

export const TravelersField: React.FunctionComponent<TravelersFieldProps> = inject("analytics")(
  observer(
    ({
      triggerType = TravelersTriggerTypes.FAKE_INPUT,
      handleMenuTriggerState,
      lobState,
      onSave,
      setChange,
      isChanged,
      noRehydratationValues,
      config,
      metadata,
      travelers,
      tripLobType,
      analytics,
      isApiCallEnabled,
    }) => {
      const inputRef = React.useRef<any | null>(null);
      const errorRef = React.useRef<HTMLInputElement | null>(null);
      const [showError, setError] = React.useState<boolean>(false);
      // we need a unique queryRouteId because the dialog was taking the first one if we repeat the id
      const [isDialogOpen, dialogActions, TravelersDialogComponent] = useDialog(
        `travelers-dialog-${lobState.config.elementId}`
      );
      const {
        numOfAdults,
        numOfChildren,
        numOfInfants,
        flightSearch,
        hotwireFlightSearch,
        hotelSearch,
        hotwireHotelSearch,
        packageSearch,
        hotwirePackageSearch,
        cruiseSearch,
        groundTransportSearch,
        thirdSearch,
      } = metadata;

      const { withFlight, withRooms, withInfants } = config;

      const numOfTravelers =
        noRehydratationValues?.numOfTravelers && !numOfAdults
          ? noRehydratationValues?.numOfTravelers
          : numOfAdults + numOfChildren + (withInfants ? numOfInfants : 0);
      const [showAdaptive, setAdaptive] = React.useState(false);
      const [name, setName] = React.useState<string | undefined>(noRehydratationValues?.name);
      const [useHiddenInputs, setUseHiddenInputs] = React.useState(false);
      const [fauxInputClasses, setFauxInputClasses] = React.useState("guests-hidden");
      const summary = travelersSummary(config, travelers.rooms, numOfTravelers);
      const { formatText } = useLocalization();

      const travelersRoomToken = config.roomsGuestsToken;
      const numberOfTravelersToken = config.travelersToken;
      const ariaLabelFirstArgs = travelers.rooms.length;
      const travelersAriaLabel = config.withRooms
        ? formatText(travelersRoomToken, ariaLabelFirstArgs, numOfTravelers)
        : formatText(numberOfTravelersToken, numOfTravelers);

      const setDefaulTravelers = () => {
        const defaultAdultsHotelPackage = 2;
        const defaultAdutlsNonHotelPackage = lobState instanceof FlightWizardState ? 1 : 2;
        const adults =
          (withRooms && withFlight) || (tripLobType && withRooms) || (!withRooms && !withFlight)
            ? defaultAdultsHotelPackage
            : defaultAdutlsNonHotelPackage;
        const initializedTravelers: Travelers = {
          rooms: [
            {
              adults,
              children: [],
              infants: [],
            },
          ],
          infantSeating: InfantSeating.ON_LAP,
        };

        if (config.withRooms) lobState.updateHotelTravelersSelection?.(initializedTravelers);
        else lobState.updateNonHotelTravelersSelection?.(initializedTravelers);
      };

      const validateHotelPackage = lobState instanceof HotelWizardState ? lobState.validateHotelPackage() : true;
      /**
       * This hook is specifically for opening the dialog if we have more than the limit of travelers while adding a flight or car in hotel lob
       */
      React.useEffect(() => {
        if (showError && errorRef.current) {
          errorRef.current?.focus();
          setError(false);
          errorRef.current = null;
        }

        if (!validateHotelPackage && !showAdaptive) {
          dialogActions.openDialog();
          setAdaptive(true);
          setError(true);
        }
      });

      const setErrorRef = (ref: React.RefObject<HTMLInputElement>) => {
        errorRef.current = ref.current;
      };

      /**
       * This hook is reached for rehydratation, so if JS is enabled then initial values are set here, if JS is disabled then this hook is never executed
       */
      React.useEffect(() => {
        setName(undefined);
        setFauxInputClasses("");
        setUseHiddenInputs(true);

        if (!isChanged) {
          setDefaulTravelers();
        }
        if (handleMenuTriggerState && showAdaptive) {
          handleMenuTriggerState(false);
        }
      }, [showAdaptive]);

      const handleFakeInputClickMobile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        dialogActions.openDialog();

        tracker.trackEvent(_guests + ACTION_OPEN);
        setAdaptive(!showAdaptive);
        liveAnnounce(formatText(locKeys.roomPickerOpened), Level.POLITE);
      };

      const isTravelersValid = () => {
        if (lobState.validateTravelersField) {
          return lobState.validateTravelersField();
        }

        return true;
      };

      const handleAdaptiveState = () => setAdaptive((prevState) => (!isTravelersValid() && prevState) || !prevState);

      const handleFakeInputClickDesktop = (event: React.MouseEvent) => {
        handleAdaptiveState();
        event.preventDefault();
        tracker.trackEvent(_guests + ACTION_OPEN);
        liveAnnounce(formatText(locKeys.roomPickerOpened), Level.POLITE);
      };

      const renderAdaptiveDialog = (children: JSX.Element) => {
        return (
          <UitkSheetTransition isVisible={isDialogOpen}>
            <TravelersDialogComponent>{children}</TravelersDialogComponent>
          </UitkSheetTransition>
        );
      };

      const adaptiveTrigger = () => {
        return triggerType === TravelersTriggerTypes.LINK_BUTTON ? triggerTypeLink() : triggerTypeInput();
      };

      const triggerTypeInput = () => {
        return (
          <Viewport>
            <ViewSmall>
              <UitkFakeInput
                icon={config.icon}
                className={fauxInputClasses}
                label={formatText(config.labelToken)}
                aria-label={travelersAriaLabel}
                value={summary}
                name={name}
                data-testid="travelers-field-trigger"
                onClick={handleFakeInputClickMobile}
                domRef={inputRef}
              />
            </ViewSmall>
            <ViewLarge>
              <UitkFakeInput
                icon={config.icon}
                className={fauxInputClasses}
                label={formatText(config.labelToken)}
                aria-label={travelersAriaLabel}
                onClick={handleFakeInputClickDesktop}
                value={summary}
                name={name}
                data-testid="travelers-field-trigger"
                domRef={inputRef}
              />
            </ViewLarge>
          </Viewport>
        );
      };

      const triggerTypeLink = () => {
        return (
          <UitkLink noWrap>
            <button type="button" data-testid="travelers-field" ref={inputRef} aria-label={travelersAriaLabel}>
              {summary}
              <UitkIcon name="expand_more" />
            </button>
          </UitkLink>
        );
      };

      const onDismiss = () => {
        if (isTravelersValid()) {
          if (inputRef.current) {
            inputRef.current.focus();
          }
          dialogActions.closeDialog();
          tracker.trackEvent(_guests + ACTION_CLOSE);
        }
        handleAdaptiveState();
      };

      const onChange = (travelers: Travelers) => onSave("CHANGE", travelers);

      const onDataSave = (travelersData: Travelers) => {
        if (isTravelersValid()) {
          if (inputRef.current) {
            inputRef.current.focus();
          }

          if (onSave) {
            if (setChange && !isChanged) {
              setChange();
            }
            onSave("SUBMIT", travelersData);
          }

          if (isDialogOpen) {
            dialogActions.closeDialog();
          }

          tracker.trackEvent(_guests + ACTION_DONE);
        }
        handleAdaptiveState();
      };
      const tracker = getTracker(lobState.trackingEnabled, analytics, lobState.moduleName);
      const _guests = "guests.";

      const lobId = lobState.config.elementId || "";
      const lobAlignRight = lobId.includes("flight") || lobId.includes("hotel");
      const menuPosition = lobAlignRight ? PositionType.RIGHT : PositionType.LEFT;

      return (
        <>
          <AdaptiveMenuDialog
            showAdaptive={showAdaptive}
            dialogWrapper={renderAdaptiveDialog}
            handleDialogClick={handleFakeInputClickMobile}
            handleMenuClick={handleFakeInputClickDesktop}
            adaptiveTrigger={adaptiveTrigger}
            menuPosition={menuPosition}
            toolbar={{
              toolbarTitle: formatText(config.dialogTitleToken),
              navigationContent: {
                navIconLabel: formatText(config.dialogCancelLabelToken),
                onClick: onDismiss,
              },
            }}
          >
            <TravelersDialog
              travelers={travelers}
              config={config}
              onSave={onDataSave}
              onChange={onChange}
              lobState={lobState}
              setInputsRefs={setErrorRef}
            />
          </AdaptiveMenuDialog>

          {useHiddenInputs && (
            <TravelersHiddenInputs
              hiddenInputType={config.hiddenInputType}
              flightSearch={flightSearch}
              hotwireFlightSearch={hotwireFlightSearch}
              hotelSearch={hotelSearch}
              hotwireHotelSearch={hotwireHotelSearch}
              groundTransportSearch={groundTransportSearch}
              packageSearch={packageSearch}
              hotwirePackageSearch={hotwirePackageSearch}
              cruiseSearch={cruiseSearch}
              thirdSearch={thirdSearch}
              isApiCallEnabled={isApiCallEnabled}
            />
          )}
        </>
      );
    }
  )
);
