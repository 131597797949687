import * as React from "react";
import { UitkTertiaryButton } from "@egds/react-core/button";
import { UitkStepInput } from "@egds/react-core/step-input";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { AgeSelect } from "./AgeSelect";
import { locKeys } from "../l10n";
import { RoomProps, Room as RoomInterface } from "../typings";
import { useLocalization } from "@shared-ui/localization-context";
import { getTracker } from "utils/TrackingUtils";
import { inject } from "mobx-react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export const Room = inject(
  "analytics",
  "context"
)((props: RoomProps) => {
  const roomRef: React.RefObject<HTMLHeadingElement> = React.createRef();
  props.roomRefs.push(roomRef);

  const { formatText } = useLocalization();

  const getMaxInput = (
    maxTravelersPerRoom: number | undefined,
    { adults, children }: RoomInterface,
    maxOccupantPerRoom: number,
    currentOccupantCount: number
  ) => {
    return maxTravelersPerRoom
      ? currentOccupantCount + Math.min(maxOccupantPerRoom, maxTravelersPerRoom - (adults + children.length))
      : maxOccupantPerRoom;
  };

  const onUpdateRoomAdults = (roomIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setTravelers({
      rooms: props.travelers.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            adults: parseInt(event.target.value, 10),
          };
        }

        return room;
      }),
      infantSeating: props.travelers.infantSeating,
    });

    tracker.trackEvent(`${_guests}${roomIndex}.adults`);
  };

  const onUpdateRoomChildren = (roomIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChildrenAdded = parseInt(event.target.value, 10) > props.travelers.rooms[roomIndex].children.length;
    const ageValue = props.config.withAges && props.config.validateChildInfantValues! ? -1 : props.config.minChildAge;
    props.setTravelers({
      rooms: props.travelers.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            children: isChildrenAdded
              ? room.children.concat({
                  age: ageValue,
                })
              : room.children.slice(0, -1),
          };
        }

        return room;
      }),
      infantSeating: props.travelers.infantSeating,
    });

    tracker.trackEvent(`${_guests}${roomIndex}.children`);
  };

  const onUpdateRoomInfants = (roomIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isInfantAdded = parseInt(event.target.value, 10) > props.travelers.rooms[roomIndex].infants.length;
    const ageValue = props.config.withAges && props.config.validateChildInfantValues! ? -1 : props.config.minInfantAge;
    props.setTravelers({
      rooms: props.travelers.rooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            infants: isInfantAdded
              ? room.infants.concat({
                  age: ageValue,
                })
              : room.infants.slice(0, -1),
          };
        }

        return room;
      }),
      infantSeating: props.travelers.infantSeating,
    });

    tracker.trackEvent(`${_guests}${roomIndex}.infants`);
  };

  const onRemoveRoom = (roomIndex: number) => () => {
    props.setTravelers({
      rooms: props.travelers.rooms.filter((room, index) => roomIndex !== index),
      infantSeating: props.travelers.infantSeating,
    });
    liveAnnounce(formatText(locKeys.roomRemoved, ""), Level.POLITE);
    props.setTotalRoomRefs(props.totalRoomRefs - 1);

    tracker.trackEvent(`${_guests}remove-room`);
  };

  const onUpdateChildAge = (roomIndex: number, childIndex: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const age = parseInt(event.target.value, 10);
    props.setTravelers({
      rooms: props.travelers.rooms.map((room, i) => {
        if (i === roomIndex) {
          return {
            ...room,
            children: room.children.map((child, j) => {
              if (j === childIndex) {
                return { age };
              }

              return child;
            }),
          };
        }

        return room;
      }),
      infantSeating: props.travelers.infantSeating,
    });
    liveAnnounce(formatText(locKeys.childUpdated, childIndex + 1, age), Level.POLITE);
  };

  const onUpdateInfantAge = (roomIndex: number, infantIndex: number) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const age = parseInt(event.target.value, 10);
    props.setTravelers({
      rooms: props.travelers.rooms.map((room, i) => {
        if (i === roomIndex) {
          return {
            ...room,
            infants: room.infants.map((infant, j) => {
              if (j === infantIndex) {
                return { age };
              }

              return infant;
            }),
          };
        }

        return room;
      }),
      infantSeating: props.travelers.infantSeating,
    });
    liveAnnounce(formatText(locKeys.infantUpdated, infantIndex + 1, age), Level.POLITE);
  };

  const tracker = getTracker(props.lobState.trackingEnabled, props.analytics, props.lobState.moduleName);
  const _guests = "guests.";
  const lobId = props.lobState.config.elementId || "";
  const isFlight = lobId.includes("flight");
  const isCars = lobId.includes("car");

  const increaseAdults =
    isFlight || isCars ? formatText(locKeys.increaseAdults) : formatText(locKeys.increaseAdultsInRoom, props.index + 1);
  const decreaseAdults =
    isFlight || isCars ? formatText(locKeys.decreaseAdults) : formatText(locKeys.decreaseAdultsInRoom, props.index + 1);
  const increaseChildren =
    isFlight || isCars
      ? formatText(locKeys.increaseChildren)
      : formatText(locKeys.increaseChildrenInRoom, props.index + 1);
  const decreaseChildren =
    isFlight || isCars
      ? formatText(locKeys.decreaseChildren)
      : formatText(locKeys.decreaseChildrenInRoom, props.index + 1);
  const increaseInfants =
    isFlight || isCars
      ? formatText(locKeys.increaseInfants)
      : formatText(locKeys.increaseInfantInRoom, props.index + 1);
  const decreaseInfants =
    isFlight || isCars
      ? formatText(locKeys.decreaseInfants)
      : formatText(locKeys.decreaseInfantsInRoom, props.index + 1);

  const roomPaddingSpacing = props.index === 1 ? "six" : "unset";
  const roomMarginSpacing = props.index === 1 ? "two" : "unset";

  return (
    <UitkSpacing padding={{ blockstart: roomPaddingSpacing }} margin={{ blockstart: roomMarginSpacing }}>
      <div className="roomPickerRoom" data-testid={`room-${props.index + 1}`}>
        {props.config.withRooms && (
          <UitkSpacing padding={{ blockstart: "two" }}>
            <div ref={roomRef} data-testid="room-header">
              <UitkHeading
                size={7}
                tag="h3"
                data-testid={`room-header-${props.index}`}
                aria-label={formatText(locKeys.roomHeaderAriaLabel, props.index + 1, props.travelers.rooms.length)}
                tabIndex={0}
              >
                {formatText(locKeys.roomHeaderTitle, props.index + 1)}
              </UitkHeading>
            </div>
          </UitkSpacing>
        )}
        <UitkStepInput
          label={formatText(locKeys.adults)}
          incrementText={increaseAdults}
          decrementText={decreaseAdults}
          description={formatText(props.config?.adultsCaptionToken || "")}
          max={getMaxInput(
            props.config.maxTravelersPerRoom,
            props.travelers.rooms[props.index],
            props.config.maxAdultsPerRoom,
            props.room.adults
          )}
          min={props.config.minAdultsPerRoom}
          value={props.room.adults}
          onChange={onUpdateRoomAdults(props.index)}
          className="adultStepInput"
          id={`adult-input-${props.index}`}
        />
        <UitkStepInput
          label={formatText(locKeys.children)}
          incrementText={increaseChildren}
          decrementText={decreaseChildren}
          description={formatText(props.config.childrenCaptionToken)}
          max={getMaxInput(
            props.config.maxTravelersPerRoom,
            props.travelers.rooms[props.index],
            props.config.maxChildrenPerRoom,
            props.room.children.length
          )}
          min={props.config.minChildrenPerRoom}
          value={props.room.children.length}
          onChange={onUpdateRoomChildren(props.index)}
          className="childStepInput"
          id={`child-input-${props.index}`}
        />
        {props.room.children && props.room.children.length > 0 && props.config.withAges && (
          <AgeSelect
            ageOptions={props.childAgeOptions}
            onChange={onUpdateChildAge}
            children={props.room.children}
            padding={{ blockstart: "one", blockend: "three" }}
            menuIndex={props.index}
            labelKey={locKeys.childAgeLabel}
            id="child-age-input"
          />
        )}
        {props.config.withInfants && (
          <>
            <UitkStepInput
              label={formatText(locKeys.infants)}
              incrementText={increaseInfants}
              decrementText={decreaseInfants}
              description={formatText(props.config.infantsCaptionToken)}
              max={props.config.maxInfantsPerRoom}
              min={props.config.minInfantsPerRoom}
              value={props.room.infants.length}
              onChange={onUpdateRoomInfants(props.index)}
              className="childStepInput"
              id={`infant-input-${props.index}`}
            />
            {props.config.withAges && (
              <AgeSelect
                ageOptions={props.infantAgeOptions}
                onChange={onUpdateInfantAge}
                children={props.room.infants}
                padding={{ blockstart: "one" }}
                dataTestid="infant-ages"
                menuIndex={props.index}
                labelKey={locKeys.infantAgeLabel}
                id="infant-age-input"
              />
            )}
          </>
        )}
        {props.index > 0 && (
          <UitkSpacing padding={{ blockstart: "four" }}>
            <UitkLayoutFlex justifyContent="end">
              <UitkLayoutFlexItem>
                <div className="removeRoomButton">
                  <UitkTertiaryButton
                    onClick={onRemoveRoom(props.index)}
                    aria-label={formatText(locKeys.removeRoomAria, props.index + 1, props.travelers.rooms.length)}
                  >
                    {formatText(locKeys.removeRoom)}
                  </UitkTertiaryButton>
                </div>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkSpacing>
        )}
      </div>
    </UitkSpacing>
  );
});
