import * as React from "react";
import { observer } from "mobx-react";
import { UitkSelect } from "@egds/react-core/input";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { Child, Infant, AgeOption } from "../typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";

export interface AgeSelectProps {
  ageOptions: AgeOption[];
  children: Child[] | Infant[];
  dataTestid?: string;
  id: string;
  labelKey: string;
  menuIndex: number;
  onChange: (roomIndex: number, infantIndex: number) => (event: React.ChangeEvent<HTMLSelectElement>) => void;
  padding: {};
}

export const AgeSelect = observer(
  ({ onChange, id, labelKey, dataTestid, children, ageOptions, padding, menuIndex }: AgeSelectProps) => {
    const { formatText } = useLocalization();
    const onChangeAgeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const index = +event.currentTarget.id.slice(-1);
      onChange(menuIndex, index)(event);
    };

    return (
      <div>
        <UitkSpacing padding={padding}>
          <UitkLayoutGrid columns={2} space="two" data-testid={dataTestid}>
            {children.map((child: Child | Infant, index: number) => {
              const label = formatText(labelKey, index + 1);
              const selectId = `${id}-${menuIndex}-${index}`;
              const validId = isNaN(+selectId.slice(-1)) ? `${index}` : selectId;

              return (
                <UitkLayoutGridItem colSpan={1} key={index}>
                  <UitkSelect
                    label={label}
                    value={child.age === -1 ? "" : child.age.toString()}
                    onChange={onChangeAgeSelect}
                    id={validId}
                    emptyState
                  >
                    {ageOptions.map((option: AgeOption) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </UitkSelect>
                </UitkLayoutGridItem>
              );
            })}
          </UitkLayoutGrid>
        </UitkSpacing>
      </div>
    );
  }
);
